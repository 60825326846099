<template>
  <div class="mx-a mb-16">
    <div class="category-wrap">
      <div class="category-header-wrap">파트너 카테고리 설정</div>
      <div class="category-content-wrap">
        <!-- 상위 카테고리 -->
        <div>
          <div class="flex">
            <input-text
              wp-cls="w-100"
              style="margin-right: 13px"
              v-model="highCateTitle"
            />
            <button-common
              :size="SIZE_T"
              :clr="CLR_M"
              @click="addPartnerCategory(true)"
              >추가</button-common
            >
          </div>
          <div class="category-table-wrap">
            <div class="category-table-header">상위 카테고리</div>
            <div class="category-table-content">
              <div
                class="category-table-row"
                v-for="highCate in highCateList"
                :key="highCate.esntlId"
              >
                <div class="category-table-num">{{ highCate.esntlId }}</div>
                <div
                  :class="`category-table-title${
                    highCate.esntlId === selectHighCateId ? '-selected' : ''
                  }`"
                  style="cursor: pointer"
                  @click="selectHighCate(highCate.esntlId)"
                >
                  {{ highCate.title }}
                </div>
                <div class="category-table-button">
                  <img
                    src="@/assets/image/table_cancel.png"
                    alt="cancel"
                    style="width: 20px; cursor: pointer"
                    @click="deletePartnerCategory(highCate.esntlId, true)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="margin: 140px 60px 0 60px">
          <img
            src="@/assets/image/arrow_right_green.svg"
            alt="right"
            style="width: 20px; height: 20px"
          />
        </div>
        <!-- 하위 카테고리 -->
        <div>
          <div class="flex">
            <input-text
              wp-cls="w-100"
              style="margin-right: 13px"
              v-model="cateTitle"
            />
            <button-common
              :size="SIZE_T"
              :clr="CLR_M"
              @click="addPartnerCategory(false)"
              >추가</button-common
            >
          </div>
          <div class="category-table-wrap">
            <div class="category-table-header">하위 카테고리</div>
            <div class="category-table-content">
              <div
                class="category-table-row"
                v-for="cate in cateList"
                :key="cate.esntlId"
              >
                <div class="category-table-num">{{ cate.esntlId }}</div>
                <div class="category-table-title">{{ cate.title }}</div>
                <div class="category-table-button">
                  <img
                    src="@/assets/image/table_cancel.png"
                    alt="cancel"
                    style="width: 20px; cursor: pointer"
                    @click="deletePartnerCategory(cate.esntlId, false)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="category-wrap">
      <div class="category-header-wrap">프로필 카테고리 설정</div>
      <div class="category-content-wrap">
        <!-- 카테고리 A -->
        <div>
          <div class="flex">
            <input-text
              wp-cls="w-100"
              style="margin-right: 13px"
              v-model="cateA"
            />
            <button-common :size="SIZE_T" :clr="CLR_M" @click="addCategoryA"
              >추가</button-common
            >
          </div>
          <div class="category-table-wrap">
            <div class="category-table-header">A</div>
            <div class="category-table-content">
              <div
                class="category-table-row"
                v-for="cate in categoryA"
                :key="cate.esntlId"
              >
                <div class="category-table-num">{{ cate.esntlId }}</div>
                <div class="category-table-title">{{ cate.title }}</div>
                <div class="category-table-button">
                  <img
                    src="@/assets/image/table_cancel.png"
                    alt="cancel"
                    style="width: 20px; cursor: pointer"
                    @click="deleteCategoryA(cate.esntlId)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 카테고리 B -->
        <div style="margin-left: 120px">
          <div class="flex">
            <input-text
              wp-cls="w-100"
              style="margin-right: 13px"
              v-model="cateB"
            />
            <button-common :size="SIZE_T" :clr="CLR_M" @click="addCategoryB"
              >추가</button-common
            >
          </div>
          <div class="category-table-wrap">
            <div class="category-table-header">B</div>
            <div class="category-table-content">
              <div
                class="category-table-row"
                v-for="cate in categoryB"
                :key="cate.esntlId"
              >
                <div class="category-table-num">{{ cate.esntlId }}</div>
                <div class="category-table-title">{{ cate.title }}</div>
                <div class="category-table-button">
                  <img
                    src="@/assets/image/table_cancel.png"
                    alt="cancel"
                    style="width: 20px; cursor: pointer"
                    @click="deleteCategoryB(cate.esntlId)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="category-content-wrap">
        <!-- 카테고리 C -->
        <div>
          <div class="flex">
            <input-text
              wp-cls="w-100"
              style="margin-right: 13px"
              v-model="cateC"
            />
            <button-common :size="SIZE_T" :clr="CLR_M" @click="addCategoryC"
              >추가</button-common
            >
          </div>
          <div class="category-table-wrap">
            <div class="category-table-header">C</div>
            <div class="category-table-content">
              <div
                class="category-table-row"
                v-for="cate in categoryC"
                :key="cate.esntlId"
              >
                <div class="category-table-num">{{ cate.esntlId }}</div>
                <div class="category-table-title">{{ cate.title }}</div>
                <div class="category-table-button">
                  <img
                    src="@/assets/image/table_cancel.png"
                    alt="cancel"
                    style="width: 20px; cursor: pointer"
                    @click="deleteCategoryC(cate.esntlId)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 카테고리 D -->
        <div style="margin-left: 120px">
          <div class="flex">
            <input-text
              wp-cls="w-100"
              style="margin-right: 13px"
              v-model="cateD"
            />
            <button-common :size="SIZE_T" :clr="CLR_M" @click="addCategoryD"
              >추가</button-common
            >
          </div>
          <div class="category-table-wrap">
            <div class="category-table-header">D</div>
            <div class="category-table-content">
              <div
                class="category-table-row"
                v-for="cate in categoryD"
                :key="cate.esntlId"
              >
                <div class="category-table-num">{{ cate.esntlId }}</div>
                <div class="category-table-title">{{ cate.title }}</div>
                <div class="category-table-button">
                  <img
                    src="@/assets/image/table_cancel.png"
                    alt="cancel"
                    style="width: 20px; cursor: pointer"
                    @click="deleteCategoryD(cate.esntlId)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";

export default {
  name: "SystemCategory",
  mixins: [],
  components: {
    draggable,
  },

  /*** data ***/
  data() {
    return {
      highCateTitle: "",
      cateTitle: "",
      cateA: "",
      cateB: "",
      cateC: "",
      cateD: "",
      highCateList: [],
      cateList: [],
      categoryA: [],
      categoryB: [],
      categoryC: [],
      categoryD: [],
      selectHighCateId: null,
      isReset: false,
      toRoute: {
        path: "",
        meta: "",
      },
    };
  },

  /*** created ***/
  created() {},

  /*** mounted ***/
  async mounted() {
    await this.getHighCategoryList();
    await this.getCategoryA();
    await this.getCategoryB();
    await this.getCategoryC();
    await this.getCategoryD();
  },

  // beforeRouteLeave(to, from, next) {
  //   this.toRoute.path = to.fullPath;
  //   this.toRoute.meta = to.meta.id;
  //   if (this.isReset) {
  //     next();
  //   } else {
  //     next(false);
  //     if (!this.isReset) {
  //       this.onPageReset();
  //     }
  //   }
  // },

  /*** computed ***/
  computed: {},

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {
    /** @ 카테고리 상위키 조회 ***/
    async getHighCategoryList() {
      const rs = await this.$axios({
        url: "/specialty",
        params: { highEsntlId: "" },
      });
      if (rs.result === "SUC") {
        this.highCateList = rs.resultList;
        console.log("상위 카테고리 조회 완료");
      } else {
        console.log("상위 카테고리 조회 실패");
      }
    },

    /** @ 카테고리 하위키 조회 ***/
    async selectHighCate(id) {
      this.selectHighCateId = id;
      const rs = await this.$axios({
        url: "/specialty",
        params: { highEsntlId: id },
      });
      if (rs.result === "SUC") {
        this.cateList = rs.resultList;
        console.log("하위 카테고리 조회 완료");
      } else {
        console.log("하위 카테고리 조회 실패");
      }
    },

    /** @ 카테고리 A 조회 ***/
    async getCategoryA() {
      const rs = await this.$axios({
        url: "/categoryA",
        params: {},
      });
      if (rs.result === "SUC") {
        this.categoryA = rs.resultList;
        console.log("카테고리 A 조회 완료");
      } else {
        console.log("카테고리 A 조회 실패");
      }
    },

    /** @ 카테고리 B 조회 ***/
    async getCategoryB() {
      const rs = await this.$axios({
        url: "/categoryB",
        params: {},
      });
      if (rs.result === "SUC") {
        this.categoryB = rs.resultList;
        console.log("카테고리 B 조회 완료");
      } else {
        console.log("카테고리 B 조회 실패");
      }
    },

    /** @ 카테고리 C 조회 ***/
    async getCategoryC() {
      const rs = await this.$axios({
        url: "/categoryC",
        params: {},
      });
      if (rs.result === "SUC") {
        this.categoryC = rs.resultList;
        console.log("카테고리 C 조회 완료");
      } else {
        console.log("카테고리 C 조회 실패");
      }
    },

    /** @ 카테고리 D 조회 ***/
    async getCategoryD() {
      const rs = await this.$axios({
        url: "/categoryD",
        params: {},
      });
      if (rs.result === "SUC") {
        this.categoryD = rs.resultList;
        console.log("카테고리 D 조회 완료");
      } else {
        console.log("카테고리 D 조회 실패");
      }
    },

    /** @ 파트너 카테고리 추가 ***/
    async addPartnerCategory(isHigh) {
      const title = isHigh ? this.highCateTitle : this.cateTitle;
      const highEsntlId = isHigh ? "" : this.selectHighCateId;
      const rs = await this.$axios({
        url: "/admin/system/specialty/add",
        params: { title: title, icon: "", highEsntlId: highEsntlId },
      });
      if (rs.result === "SUC") {
        if (isHigh) {
          this.cateList = [];
          this.getHighCategoryList();
        } else {
          this.selectHighCate(this.selectHighCateId);
        }
        console.log("파트너 카테고리 추가 완료");
      } else {
        console.log("파트너 카테고리 추가 실패");
      }
    },

    /** @ 파트너 카테고리 삭제 ***/
    async deletePartnerCategory(esntlId, isHigh) {
      const rs = await this.$axios({
        url: "/admin/system/specialty/del",
        params: { esntlId: esntlId },
      });
      if (rs.result === "SUC") {
        if (isHigh) {
          this.cateList = [];
          this.getHighCategoryList();
        } else {
          this.selectHighCate(this.selectHighCateId);
        }
        console.log("파트너 카테고리 추가 완료");
      } else {
        console.log("파트너 카테고리 추가 실패");
      }
    },

    /** @ 카테고리 A 추가 ***/
    async addCategoryA() {
      const rs = await this.$axios({
        url: "/admin/system/categoryA/add",
        params: { title: this.cateA, icon: "" },
      });
      if (rs.result === "SUC") {
        this.getCategoryA();
        console.log("카테고리 A 추가 완료");
      } else {
        console.log("카테고리 A 추가 실패");
      }
    },

    /** @ 카테고리 A 삭제 ***/
    async deleteCategoryA(esntlId) {
      const rs = await this.$axios({
        url: "/admin/system/categoryA/del",
        params: { esntlId: esntlId },
      });
      if (rs.result === "SUC") {
        this.getCategoryA();
        console.log("카테고리 A 삭제 완료");
      } else {
        console.log("카테고리 A 삭제 실패");
      }
    },

    /** @ 카테고리 B 추가 ***/
    async addCategoryB() {
      const rs = await this.$axios({
        url: "/admin/system/categoryB/add",
        params: { title: this.cateB, icon: "" },
      });
      if (rs.result === "SUC") {
        this.getCategoryB();
        console.log("카테고리 B 추가 완료");
      } else {
        console.log("카테고리 B 추가 실패");
      }
    },

    /** @ 카테고리 B 삭제 ***/
    async deleteCategoryB(esntlId) {
      const rs = await this.$axios({
        url: "/admin/system/categoryB/del",
        params: { esntlId: esntlId },
      });
      if (rs.result === "SUC") {
        this.getCategoryB();
        console.log("카테고리 B 삭제 완료");
      } else {
        console.log("카테고리 B 삭제 실패");
      }
    },

    /** @ 카테고리 C 추가 ***/
    async addCategoryC() {
      const rs = await this.$axios({
        url: "/admin/system/categoryC/add",
        params: { title: this.cateC, icon: "" },
      });
      if (rs.result === "SUC") {
        this.getCategoryC();
        console.log("카테고리 C 추가 완료");
      } else {
        console.log("카테고리 C 추가 실패");
      }
    },

    /** @ 카테고리 C 삭제 ***/
    async deleteCategoryC(esntlId) {
      const rs = await this.$axios({
        url: "/admin/system/categoryC/del",
        params: { esntlId: esntlId },
      });
      if (rs.result === "SUC") {
        this.getCategoryC();
        console.log("카테고리 C 삭제 완료");
      } else {
        console.log("카테고리 C 삭제 실패");
      }
    },

    /** @ 카테고리 D 추가 ***/
    async addCategoryD() {
      const rs = await this.$axios({
        url: "/admin/system/categoryD/add",
        params: { title: this.cateD, icon: "" },
      });
      if (rs.result === "SUC") {
        this.getCategoryD();
        console.log("카테고리 D 추가 완료");
      } else {
        console.log("카테고리 D 추가 실패");
      }
    },

    /** @ 카테고리 D 삭제 ***/
    async deleteCategoryD(esntlId) {
      const rs = await this.$axios({
        url: "/admin/system/categoryD/del",
        params: { esntlId: esntlId },
      });
      if (rs.result === "SUC") {
        this.getCategoryD();
        console.log("카테고리 D 삭제 완료");
      } else {
        console.log("카테고리 D 삭제 실패");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.category-wrap {
  width: 100%;
  background: #fff;
  margin-top: 10px;
  border: 1px solid #d8dae5;
}
.category-header-wrap {
  width: 100%;
  border-bottom: 1px solid #d8dae5;
  padding: 16px 32px;
  font-size: 16px;
  font-weight: 500;
  color: #515466;
}
.category-content-wrap {
  padding: 50px 32px 80px 32px;
  display: flex;
}
.category-table-wrap {
  width: 480px;
  margin-top: 10px;
  border: 1px solid #d8dae5;
}
.category-table-header {
  width: 100%;
  height: 36px;
  background: #f1f2f8;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3a3d4d;
  border-bottom: 1px solid #d8dae5;
}
.category-table-content {
  width: 100%;
  height: 300px;
  overflow: auto;
}
.category-table-row {
  display: flex;
  width: 100%;
  height: 36px;
  border-bottom: 1px solid #d8dae5;
}
.category-table-num {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  height: 100%;
  border-right: 1px solid #d8dae5;
}
.category-table-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 100%;
  border-right: 1px solid #d8dae5;
}
.category-table-title-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 100%;
  border-right: 1px solid #d8dae5;
  background: #d9d9d94d;
}
.category-table-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  height: 100%;
}
</style>
